import React from 'react';
import cx from 'classnames';

import logos from '../assets/operator-logos.svg';
import styles from '../styles/OperatorLogo.module.scss';

export type OperatorName =
    | 'Ario'
    | 'Blue Duck'
    | 'Citi Bike'
    | 'Ford GoBike'
    | 'Indigo Weel'
    | 'Revel Transit'
    | 'Share Now'
    | 'Beam'
    | 'Biketown'
    | 'Bird'
    | 'Bolt'
    | 'Charge'
    | 'Circ'
    | 'Dott'
    | 'Flamingo'
    | 'Free2Move'
    | 'Gotcha'
    | 'Gruv'
    | 'Helbiz'
    | 'HelloRide'
    | 'Hive'
    | 'Hopp'
    | 'HOPR'
    | 'Jump'
    | 'Lime'
    | 'Lime Arvada'
    | 'Lime Boulder'
    | 'Lime Denver'
    | 'Lyft'
    | 'Lynx'
    | 'Neuron'
    | 'OGO'
    | 'Ojo'
    | 'Onzo'
    | 'Pace'
    | 'Pony'
    | 'Razor'
    | 'Revel'
    | 'Roll'
    | 'Scoot'
    | 'Scougar'
    | 'Serve Robotics'
    | 'Shared'
    | 'Sherpa'
    | 'Skip'
    | 'Spin'
    | 'Superpedestrian'
    | 'Taxify'
    | 'Tayo'
    | 'Tier'
    | 'Uscooters'
    | 'Veo'
    | 'Voi'
    | 'Wheels'
    | 'Wind';

interface OperatorLogoProps {
    className?: string;
    operator: OperatorName;
}

function OperatorLogo({ operator, className = '' }: OperatorLogoProps) {
    const operatorId = operator.toLowerCase().split(' ').join('-');

    return (
        <svg className={cx(styles.icon, className)}>
            <use xlinkHref={`${logos}#logo-${operatorId}`} />
        </svg>
    );
}

export default OperatorLogo;
